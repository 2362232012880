"use client";
import React, { useMemo } from "react";
import Link from "next/link";

const RedirectLink = ({ name, id, section, listData }) => {
  const link = name && id ? `/product?name=${name}&id=${id}` : "/product";
  const exploreAllLink = name ? `/product?name=${name}` : "/product";
  const selectedName = useMemo(() => {
    return listData?.find((item) => item._id === id)?.name;
  }, [listData, id]);
  return (
    <div className="flex flex-col items-center justify-center py-2 my-5">

      {
        (selectedName && name !== 'category') && (
          <Link
            href={link}
            className="w-fit flex font-normal text-center text-lg gap-2 items-center text-gray-700 px-4 rounded  "
          >
            {` Explore ${selectedName} --> `}
          </Link>
        )
      }

      <Link
        href={exploreAllLink}
        className="w-fit flex font-normal text-center text-lg gap-2 items-center text-gray-700 px-4 rounded  "
      >
        {`Explore all ${section} -->`}
      </Link>

    </div>
  );
};

export default RedirectLink;
