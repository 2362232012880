import React, { useRef, useState, useEffect } from "react";

const Slider = ({ children, gap }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [shouldHideButtons, setShouldHideButtons] = useState(false);

  // useEffect(() => {
  //   const containerWidth = containerRef.current.offsetWidth;
  //   const contentWidth = contentRef.current.offsetWidth;
  //   setShouldHideButtons(contentWidth <= containerWidth);
  // }, [children]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartX(e.clientX);
    setScrollLeft(containerRef.current.scrollLeft);
    containerRef.current.style.scrollBehavior = "auto";
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    containerRef.current.style.scrollBehavior = "smooth";
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const deltaX = e.clientX - startX;
    containerRef.current.scrollLeft = scrollLeft - deltaX;
  };

  const handleScrollLeft = () => {
    containerRef.current.scrollLeft -= 350; // Adjust the scroll amount as needed
  };

  const handleScrollRight = () => {
    containerRef.current.scrollLeft += 350; // Adjust the scroll amount as needed
  };

  return (
    <div className="w-full overflow-x-hidden relative md:px-10 ">
      {!shouldHideButtons && (
        <button
          onClick={handleScrollLeft}
          className="text-xl font-medium absolute left-0 top-1/2  transform -translate-y-1/2 z-10 bg-white border border-gray-300 rounded-full h-8 w-8 flex items-center justify-center text-gray-600 focus:outline-none transition duration-300 hover:bg-gray-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
            />
          </svg>
        </button>
      )}
      {!shouldHideButtons && (
        <button
          onClick={handleScrollRight}
          className="text-xl font-medium absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white border border-gray-300 rounded-full h-8 w-8 flex items-center justify-center text-gray-600 focus:outline-none transition duration-300 hover:bg-gray-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
            />
          </svg>
        </button>
      )}
      <div
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        ref={containerRef}
        className="w-full min-h-fit h-full flex flex-row  overflow-x-auto whitespace-no-wrap relative no-scrollbar rounded-xl p-2"
      >
        <div className={` flex flex-row  ${gap ? gap : 'gap-5'}`} ref={contentRef}>{children}</div>
      </div>
    </div>
  );
};

export default Slider;
