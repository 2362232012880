'use client'
import React from 'react';
const DisplayHeadingImage = ({ data, handleChange, id }) => {
    const { _id, name, image } = data;

    return (
        <div

            className={`relative shrink-0 w-16 h-16 xl:w-20 xl:h-20 overflow-hidden rounded-full transform transition-transform duration-300 ease-in-out hover:scale-110 hover:opacity-90 hover:shadow-lg cursor-pointer ${id === _id || id === name ? 'ring-2 ring-gray-400' : 'filter grayscale'}`}

            onMouseOver={() => handleChange(_id, name)}
        >
            <div className="w-full h-full">
                <img loading='lazy' width={100} height={100} src={image} alt={name} className="w-full h-full object-cover" />
            </div>
        </div>
    );
}

export default DisplayHeadingImage;
