import useSWR from "swr";
import BASE_URL from "@/components/constants";
const fetcher = async (arg) => {
  const [url, inView] = arg;
  if (!inView) return;
  const apiUrl = BASE_URL + url;
  const response = await fetch(apiUrl);
  const data = await response.json();
  return data;
};

const useCustomSWR = (url, inView) => {
  const { data, error, mutate } = useSWR([url, inView], fetcher, {
    revalidateOnMount: true,
    refreshWhenHidden: true,
    refreshWhenOffline: true,
    revalidateOnFocus: false,
    dedupingInterval: 3600 * 1000,
    refreshInterval: 3600 * 1000,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useCustomSWR;
