
'use client'
import React, { useState, useEffect } from "react";
import Link from "next/link";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import useCustomSWR from "@/hook/useCustomSWR";
import { useInView } from 'react-intersection-observer'
const Carousal = ({ title, bannerName }) => {
  const [isVisible, setIsVisible] = useState(false)
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '500px 0px',
    threshold: 0,
  })

  useEffect(() => {
    if (!inView) return
    setIsVisible(true)
  }, [inView])
  const { data, isLoading, isError, revalidate } = useCustomSWR(
    `/v1/cms/get-home-page-banner?title=${title}`, inView
  );
  const link = bannerName == 'brand' ? '/brand' : ''
  if (!isVisible) return <div ref={ref}></div>
  return (
    <div
      ref={ref}
      className="w-full bg-[#edeee9] object-auto   flex  py-4">
      <Swiper
        spaceBetween={20}
        centeredSlides={true}
        navigation
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        slidesPerView={2}
        loop={true}
        modules={[Autoplay, Navigation]}
      >
        {data?.response?.slider_images?.map((imageUrl, index) => (
          <SwiperSlide key={index} className="relative">
            <Link
              target="_blank"
              href={link} >
              <img
                loading="lazy"
                src={imageUrl?.img_url}
                className=""
                alt={`Slide ${index + 1}`}
              />
            </Link>
          </SwiperSlide>
        ))}

      </Swiper>
    </div>
  );
};

export default Carousal;
