'use client'
import React from 'react'
import { useInView } from 'react-intersection-observer';
import Link from 'next/link';
import useCustomSWR from '@/hook/useCustomSWR';
const Wfh = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '500px 0px',
        threshold: 0,
    });
    const { data, error } = useCustomSWR(`/v1/categories/get-tags-category?tag=wfh&page=1&limit=10`, inView)

    const { data: banner } = useCustomSWR(`/v1/cms/get-home-page-banner?title=wfh-page-banner`, inView)
    const image = banner?.response?.slider_images[0]?.img_url;
    if (!inView) return <div ref={ref}></div>;
    return (
        <div ref={ref} className="flex flex-col">
            <div className="mb-10">
                <p className="text-center  font-bold   text-[2rem]  py-1 uppercase">
                    Boost Your Productivity
                </p>
                <p className="text-sm text-opacity-50 font-normal text-center">
                    {" "}
                    Explore Our Collection That Makes Remote Work Smoother
                </p>
            </div>
            <div className='w-full '>
                <Link href='/collections/wfh'>
                    <img src={image} loading='lazy' className='w-full' alt='21genx wfh banner' />
                </Link>
            </div>
            <div className='flex flex-row flex-wrap gap-2 md:gap-5 justify-center my-5'>
                {
                    data?.results?.map((category, index) => {
                        return (
                            <Link key={category._id} href={category.childCategoriesPath} className={`shrink-0 basis-[18%] relative rounded-xl overflow-hidden cursor-pointer `}

                            >
                                <div>
                                    <img loading='lazy' src={category.category_img} alt={category.category_name} className='w-full h-16  sm:h-24  md:h-28 lg:h-40 object-cover rounded-md' />
                                </div>

                                <div className='hidden sm:block absolute bottom-0 inset-x-0 bg-black bg-opacity-10 backdrop-blur'>
                                    <h3 className='text-[10px] sm:text-xs lg:text-base text-center lg:leading-10 font-semibold text-white'>{category.category_name}</h3>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
            <a href={'/collections/wfh'} className="text-lg flex justify-center items-center  uppercase underline" >Discover More {` -->`}</a>
        </div>

    )
}

export default Wfh