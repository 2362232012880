"use client";
import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import "../css/home.css";
import DisplayHeadingImage from "../DisplayHeadingImage";
import ProductCard from "../ProductCard";
import RedirectLink from "../RedirectLink";
import { useInView } from "react-intersection-observer";
import useCustomSWR from "@/hook/useCustomSWR";
const AwardsList = ({ listData }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '500px 0px',
    threshold: 0,
  });

  useEffect(() => {
    if (!inView) return;
    setIsVisible(true);
  }, [inView]);
  const [awardId, setAwardId] = useState();

  useEffect(() => {
    if (!listData) return;
    if (listData[0]?._id) {
      setAwardId(listData[0]?._id);
    }
  }, [listData]);
  const { data, isLoading, isError, revalidate } = useCustomSWR(
    `/v1/products/get-all-products?filter[awards][$eq]=${awardId}&page=1&limit=6&random=yes`
    , inView);
  const productList = data?.productList;
  const handleAward = (id) => {
    setAwardId(id);
  };
  if (!isVisible) return <div ref={ref}></div>;
  return (
    <div ref={ref} className="flex flex-col">
      <div className="mb-10">
        <p className="text-center  font-bold   text-[2rem]  py-1 uppercase">
          Recognized Worldwide
        </p>
        <p className="text-sm text-opacity-50 font-normal text-center">
          {" "}
          Step Elevate Your Experience with Excellence
        </p>
      </div>
      <div className="home-heading w-full sm:justify-center">
        {listData &&
          listData?.map((award) => (
            <DisplayHeadingImage
              key={award._id}
              data={award}
              handleChange={handleAward}
              id={awardId}
            />
          ))}
      </div>
      <div className="home-product-section">
        {isLoading &&
          Array.from({ length: 6 }, (_, index) => (
            <div className="w-full h-52 md:h-96 2xl:h-[30rem] rounded-lg overflow-hidden">
              <Skeleton
                animation="wave"
                variant="rectangular" width="100%" height="100%" />
            </div>
          ))}
        {productList?.length !== 0 &&
          productList?.map((product, index) => (
            <ProductCard
              key={product._id}
              product={product}
              style={"w-full h-52 md:h-96 2xl:h-[30rem]"}
              iconStyle={`w-7 h-7`}
              textSize={`text-sm`}
              quickViewWidth={24}
              quickViewHeight={24}
            />
          ))}
      </div>

      {productList?.length !== 0 && <RedirectLink name="award" id={awardId} section={"Award"} listData={listData} />}
    </div>
  )
}

export default AwardsList;
