"use client";
import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { cn } from "@/utils/cn";

export const TextRevealCard = ({
    text,
    revealText,
    children,
    className
}) => {
    const [widthPercentage, setWidthPercentage] = useState(0);
    const cardRef = useRef(null);
    const [left, setLeft] = useState(0);
    const [localWidth, setLocalWidth] = useState(0);
    const [isMouseOver, setIsMouseOver] = useState(false);

    useEffect(() => {
        if (cardRef.current) {
            const { left, width: localWidth } =
                cardRef.current.getBoundingClientRect();
            setLeft(left);
            setLocalWidth(localWidth);
        }
    }, []);

    function mouseMoveHandler(event) {
        event.preventDefault();

        const { clientX } = event;
        if (cardRef.current) {
            const relativeX = clientX - left;
            setWidthPercentage((relativeX / localWidth) * 100);
        }
    }

    function mouseLeaveHandler() {
        setIsMouseOver(false);
        setWidthPercentage(0);
    }
    function mouseEnterHandler() {
        setIsMouseOver(true);
    }

    const rotateDeg = (widthPercentage - 50) * 0.1;
    return (
        <div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            onMouseMove={mouseMoveHandler}
            ref={cardRef}
            className={cn(
                "w-fit",
                className
            )}
        >


            <div className="h-20  relative flex items-center overflow-hidden">
                <motion.div
                    style={{
                        width: "100%",

                    }}
                    animate={
                        isMouseOver
                            ? {
                                opacity: widthPercentage > 0 ? 1 : 0,
                                clipPath: `inset(0 ${100 - widthPercentage}% 0 0)`,
                            }
                            : {
                                clipPath: `inset(0 ${100 - widthPercentage}% 0 0)`,
                            }
                    }
                    transition={isMouseOver ? { duration: 0 } : { duration: 0.4 }}

                    className="absolute bg-[#1d1c20] z-20  will-change-transform "
                >
                    <div
                        style={{
                            backgroundImage: `url('/holographic.jpg')`,
                            backgroundSize: 'cover', // Adjust as needed
                            backgroundPosition: 'center', // Adjust as needed
                            textShadow: "4px 4px 15px rgba(0,0,0,0.5)",
                            WebkitBackgroundClip: 'text', // Clip background to text
                            backgroundClip: 'text', // Clip background to text
                            color: 'transparent', // Make text transparent
                        }}
                        className="text-sm sm:text-base md:text-[1.5rem] lg:text-[2rem] xl:text-[3rem] py-10 font-bold"
                    >
                        {revealText}
                    </div>


                </motion.div>
                <motion.div
                    animate={{
                        left: `${widthPercentage}%`,
                        rotate: `${rotateDeg}deg`,
                        opacity: widthPercentage > 0 ? 1 : 0,
                    }}
                    transition={isMouseOver ? { duration: 0 } : { duration: 0.4 }}
                    className="h-40 w-[8px] bg-gradient-to-b from-transparent via-neutral-800 to-transparent absolute z-50 will-change-transform"
                ></motion.div>

                <div className="overflow-hidden [mask-image:linear-gradient(to_bottom,transparent,white,transparent)]">
                    <p className="text-sm sm:text-base md:text-[1.5rem] lg:text-[2rem] xl:text-[3rem] py-3 md:py-5 lg:py-8 font-bold bg-clip-text text-white text-opacity-30    bg-[#323238]">

                        {text}
                    </p>
                    <MemoizedStars />
                </div>
            </div>
        </div>
    );
};

export const TextRevealCardTitle = ({
    children,
    className
}) => {
    return (
        <h2 className={twMerge("text-white text-lg mb-2", className)}>
            {children}
        </h2>
    );
};

export const TextRevealCardDescription = ({
    children,
    className
}) => {
    return (
        <p className={twMerge("text-[#a9a9a9] text-sm", className)}>{children}</p>
    );
};

const Stars = () => {
    const randomMove = () => Math.random() * 4 - 2;
    const randomOpacity = () => Math.random();
    const random = () => Math.random();
    return (
        <div className="absolute inset-0">
            {[...Array(140)].map((_, i) => (
                <motion.span
                    key={`star-${i}`}
                    animate={{
                        top: `calc(${random() * 100}% + ${randomMove()}px)`,
                        left: `calc(${random() * 100}% + ${randomMove()}px)`,
                        opacity: randomOpacity(),
                        scale: [1, 1.2, 0],
                    }}
                    transition={{
                        duration: random() * 10 + 20,
                        repeat: Infinity,
                        ease: "linear",
                    }}
                    style={{
                        position: "absolute",
                        top: `${random() * 100}%`,
                        left: `${random() * 100}%`,
                        width: `2px`,
                        height: `2px`,
                        backgroundColor: "white",
                        borderRadius: "50%",
                        zIndex: 1,
                    }}
                    className="inline-block"
                ></motion.span>
            ))}
        </div>
    );
};


export const MemoizedStars = React.memo(Stars);

