'use client';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { useInView } from 'react-intersection-observer';

const HomeVideo = () => {
    const isLaptopView = useMediaQuery("(min-width: 1024px)")
    const videoRef = useRef(null);
    const videoUrl = "https://21genxproduct.s3.ap-south-1.amazonaws.com/home_page_video/homepagevideo.mp4";
    // 'https://res.cloudinary.com/genx21/video/upload/v1690354672/home/pktnqxhwoyqgmcaar3p9.mov';

    // State to track whether video is in view
    const [isVideoInView, setIsVideoInView] = useState(false);

    // Hook to detect when video comes into view
    const { ref, inView } = useInView({
        threshold: 0.5, // Trigger when 50% of the video is visible
    });

    // Update state when video comes into view
    useEffect(() => {
        setIsVideoInView(inView);
    }, [inView]);

    const videoHeight = isLaptopView ? 'h-[calc(100vh-130px)]' : '';
    // Play or pause the video based on visibility
    useEffect(() => {
        const video = videoRef.current;
        if (video) { // Check if videoRef.current is not null
            if (isVideoInView) {
                video.play();
            } else {
                video.pause();
            }
        }
    }, [isVideoInView]);

    return (
        <div
            className={`mt-0 md:-mt-14 ${videoHeight}  relative overflow-hidden`}

        >
            <video preload="none" ref={(element) => {
                videoRef.current = element;
                ref(element);
            }} className="relative w-full object-fill" autoPlay loop muted playsInline data-wf-ignore="true" data-object-fit="fill" poster="placeholder_image.jpg">
                <source src={videoUrl} type="video/mp4" data-wf-ignore="true" />
            </video>
        </div>
    );
};

export default HomeVideo;
